import React from 'react';
import MasterForm from "./Form/MasterForm";
import {ErrorMessage, Field} from "formik";
import ReactGA from 'react-ga'
import API from "../../_helpers/API";
import CircularProgress from "@material-ui/core/CircularProgress";
import queryString from 'query-string'


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const required = value => (value ? undefined : 'Required');


class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.API = new API();
        this.textInput = null;
        this.registerFormRef = React.createRef();


        this.state = {
            camp: '',
            cid: '',
            email: '',
            submitted: false,
            success: false,
            isValidating: false
        };


        ReactGA.pageview(window.location.pathname + window.location.search);
    }


    _renderSuccess() {

        return (
            <div className="card bg-light mb-3" >
                <div className="card-body" style={{textAlign: 'center'}}>
                    <h5 className="card-title">Herzlichen Glückwunsch du hast es geschafft!</h5>
                    <p className="card-text">
                            Wir senden dir nun einen <b>Aktivierungslink</b> an deine Email Adresse.
                            <br/><br/>
                            Email: <b>{this.state.email}</b>
                            <br/><br/>
                            Nachdem du auf diesen Link geklickt hast ist dein Account sofort einsatzbereit!
                            <br/>
                            Wir wünschen dir viel Erfolg bei der Suche nach deiner Traumfrau

                    </p>
                </div>
            </div>
        )
    }

    _renderForm() {

        var {isValidating} = this.state;

        return (
            <MasterForm
                cid={this.state.cid}
                location={this.props.location}
                ref={this.registerFormRef}
                onSubmit={(values, actions) => {
                    this.API.doRegister(values).then((data) => {
                        actions.setSubmitting(false);

                        if(data.status === true || data.status === 'true') {

                            this.setState({
                                success: true,
                                isValidating: false
                            })
                        }

                    });
                }}
            >

                <MasterForm.Page
                    validate={values => {
                        let errors = {};

                        if (!values.email) {
                            errors.email = 'Bitte gib deine Emailadresse ein';
                        }

                        if(Object.keys(errors).length > 0) {
                            return errors;
                        }

                        this.setState({
                            isValidating: true
                        });
                        return new Promise(resolve => {
                            this.API.checkEmail(values.email).then((data) => {
                                resolve(true);
                            }).catch((error) => {
                                errors.email = error;
                                resolve(false);
                            });

                        }).then(() => {
                            if(Object.keys(errors).length > 0) {
                                throw errors;
                            }
                        }).finally(() => {

                            this.setState({
                                email: values.email
                            });

                            this.setState({
                                isValidating: false
                            });
                        });
                    }}
                >
                    <div className="content">
                        <img
                            style={{height: 240}}
                            className="mb-3"
                            src="./images/signup/Swipe.png"
                            srcSet="
                                ./images/signup/Swipe.png  780w,
                                ./images/signup/Swipe@2x.png 1024w,
                                ./images/signup/Swipe@3x.png 2400w"
                            alt="Bilder je nach Breite des Viewports" />


                        <h4 className="regHeader font-weight-light">Heiße Dates und Flirts aus deiner Nähe </h4>

                        <div className='form-group'>
                            <Field
                                autoFocus={true}
                                name="email"
                                component="input"
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Deine Email-Adresse"
                                validate={required}
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="field-error"
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" disabled={isValidating} className="btn btn-primary btn-block btn-lg register-form-btn loadButton">
                                <CircularProgress
                                    className="loadSpinner"
                                    style={{display: (this.state.isValidating) ? 'block' : 'none', color: 'white'}}
                                    variant="indeterminate"
                                    disableShrink
                                    size={20}
                                    thickness={4}
                                />
                                Kostenlos Registrieren
                            </button>
                        </div>

                        <small>Mit meiner Registrierung akzeptiere ich die <a target="_blank" rel="noopener noreferrer" href="https://flirtplan.de/agb.php">AGB</a> sowie die <a
                            target="_blank" rel="noopener noreferrer" href="https://flirtplan.de/datenschutz.php">Datenschutzrichtlinien</a></small>


                    </div>
                </MasterForm.Page>
                <MasterForm.Page
                    validate={values => {

                        function calculate_age(dob) {
                            var diff_ms = Date.now() - dob.getTime();
                            var age_dt = new Date(diff_ms);

                            return Math.abs(age_dt.getUTCFullYear() - 1970);
                        }
                        const errors = {};
                        if (!values.birthdate) {
                            errors.birthdate = 'Bitte gib dein Geburtsdatum an';
                        } else {
                            var age = calculate_age(new Date(values.birthdate));
                            //console.log(age);
                            if(age < 18 ) {
                                errors.birthdate = 'Du musst mindestens 18 Jahre alt sein';
                            }

                            if(age > 99) {
                                errors.birthdate = 'Du hast angegeben das du über 99 Jahre alt bist. Überprüfe deine Eingabe!';
                            }
                        }

                       return errors;
                    }}
                >

                    <div style={{color: '#2f3235', marginBottom: 60, textAlign: 'center'}}><h4 style={{fontSize: '1.425rem', marginBottom: 0}}>Super! Das hat geklappt. </h4> Lass uns nun dein Profil ausfüllen</div>

                    <h4 className="regHeader">Gib dein Geburtsdatum ein</h4>
                    <div className='form-group'>
                        <Field
                            autoFocus={true}
                            name="birthdate"
                            component="input"
                            type="date"
                            className="form-control form-control-lg"
                            validate={required}
                        />
                        <ErrorMessage
                            name="birthdate"
                            component="div"
                            className="field-error"
                        />
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-block btn-lg register-form-btn loadButton">
                            <CircularProgress
                                className="loadSpinner"
                                style={{display: (this.state.isValidating) ? 'block' : 'none', color: 'white'}}
                                variant="indeterminate"
                                disableShrink
                                size={20}
                                thickness={4}
                            />
                            Weiter
                        </button>
                    </div>
                </MasterForm.Page>
                <MasterForm.Page
                    validate={values => {
                        let errors = {};

                        if (!values.username) {
                            errors.username = 'Bitte gib einen Benutzernamen ein';
                        }



                        if (!values.password) {
                            errors.password = 'Bitte gib ein Passwort ein';
                        }

                        if(Object.keys(errors).length > 0) {
                            return errors;
                        }
                        this.setState({
                            isValidating: true
                        });
                        return new Promise(resolve => {
                            this.API.checkUsername(values.username).then((data) => {
                                resolve(true);
                            }).catch((error) => {
                                errors.username = error;
                                resolve(false);
                            });

                        }).then(() => {
                            if(Object.keys(errors).length > 0) {
                                throw errors;
                            }
                        }).finally(() => {
                            this.setState({
                                isValidating: false
                            });
                        });
                    }}

                >
                    <h4 className="regHeader">Wähle einen Benutzernamen aus</h4>
                    <div className='form-group'>
                        <Field
                            autoFocus={true}
                            name="username"
                            component="input"
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Benutzername"
                            validate={required}
                        />
                        <ErrorMessage
                            name="username"
                            component="div"
                            className="field-error"
                        />
                    </div>
                    <div className='form-group'>
                        <Field
                            name="password"
                            component="input"
                            type="password"
                            className="form-control form-control-lg"
                            placeholder="Dein Passwort"
                            validate={required}
                        />
                        <ErrorMessage
                            name="password"
                            component="div"
                            className="field-error"
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" disabled={isValidating} className="btn btn-primary btn-block btn-lg register-form-btn loadButton">
                            <CircularProgress
                                className="loadSpinner"
                                style={{display: (this.state.isValidating) ? 'block' : 'none', color: 'white'}}
                                variant="indeterminate"
                                disableShrink
                                size={20}
                                thickness={4}
                            />
                            Weiter
                        </button>
                    </div>
                </MasterForm.Page>


                <MasterForm.Page>
                    <h4 className="regHeader">Aus welchem Land kommst du?</h4>
                    <div className="countrySelection d-flex justify-content-center">


                        <Field
                            name="country"
                            component="input"
                            type="radio"
                            id="de"
                            value="105"
                            validate={required}
                        />
                        <label className="country m-2 p-2 bd-highlight" onClick={() => {
                            sleep(300).then(() => {
                                document.getElementById("next").click();
                            });
                        }} htmlFor="de">Deutschland</label>

                        <Field
                            name="country"
                            component="input"
                            type="radio"
                            id="at"
                            value="106"
                            validate={required}
                        />
                        <label className="country m-2 p-2 bd-highlight" onClick={() => {
                            sleep(300).then(() => {
                                document.getElementById("next").click();
                            });
                        }} htmlFor="at">Österreich</label>


                        <Field
                            name="country"
                            component="input"
                            type="radio"
                            id="ch"
                            value="107"
                            validate={required}
                        />
                        <label className="country m-2 p-2 bd-highlight" onClick={() => {
                            sleep(300).then(() => {
                                document.getElementById("next").click();
                            });
                        }} htmlFor="ch">Schweiz</label>


                    </div>
                    <button type="submit" id="next" className="btn btn-primary btn-block btn-lg register-form-btn invisible loadButton">
                        <CircularProgress
                            className="loadSpinner"
                            style={{display: (this.state.isValidating) ? 'block' : 'none', color: 'white'}}
                            variant="indeterminate"
                            disableShrink
                            size={20}
                            thickness={4}
                        />
                        Weiter
                    </button>
                </MasterForm.Page>
                <MasterForm.Page
                    validate={values => {
                        let errors = {};
                        let countryID = this.registerFormRef.current.state.values.country;

                        if (!values.zipcode) {
                            errors.zipcode = 'Bitte gib deine Postleitzahl ein';
                        }

                        if(Object.keys(errors).length > 0) {
                            return errors;
                        }
                        this.setState({
                            isValidating: true
                        });

                        return new Promise(resolve => {
                            this.API.checkZipCode(countryID, values.zipcode).then((data) => {
                                resolve(true);
                            }).catch((error) => {
                                errors.zipcode = error;
                                resolve(false);
                            });

                        }).then(() => {
                            if(Object.keys(errors).length > 0) {
                                this.setState({
                                    isValidating: false
                                });
                                throw errors;
                            }
                        })
                    }}

                >
                    <h4 className="regHeader">Gib bitte deine Postleitzahl ein</h4>
                    <div className='form-group'>
                        <Field
                            autoFocus={true}
                            name="zipcode"
                            component="input"
                            type="text"
                            pattern="[0-9]*"
                            className="form-control form-control-lg"
                            placeholder="Deine Postleitzahl"
                            validate={required}
                        />
                        <ErrorMessage
                            name="zipcode"
                            component="div"
                            className="field-error"
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" disabled={isValidating} className="btn btn-primary btn-block btn-lg register-form-btn loadButton">
                            <CircularProgress
                                className="loadSpinner"
                                style={{display: (this.state.isValidating) ? 'block' : 'none', color: 'white'}}
                                variant="indeterminate"
                                disableShrink
                                size={20}
                                thickness={4}
                            />
                            Profil speichern
                        </button>
                    </div>
                </MasterForm.Page>


            </MasterForm>
        )
    }

    render() {

        return (
            <div className="row loginContainer">
                <div className="col-12 col-md-5 order-2 order-md-1 pl-0 pr-0">
                    <nav className="navbar navbar-dark bg-dark pt-0 pb-0 ">
                        <img style={{height: 50, marginTop: 20, marginBottom: 20}}
                             src="./images/signup/logo.png"
                             alt=""/>
                    </nav>
                        <div className="login">
                            <div className="pt-3">
                                {
                                    (!this.state.success) ?
                                        this._renderForm() : this._renderSuccess()
                                }


                            </div>
                        </div>

                    <footer className="footer">
                        <div className="container">
                            <a target="_blank" rel="noopener noreferrer" href="https://flirtplan.de/impressum.php">Impressum</a>
                        </div>
                    </footer>
                    </div>
                <div className="col-12 col-md-7 order-1 order-md-2 background-picture pl-0 pr-0 d-none d-sm-none d-lg-block"></div>



            </div>

        );
    }
}



export default RegisterPage;

import React from 'react';
import { Formik} from 'formik';
import queryString from "query-string";


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const required = value => (value ? undefined : 'Required');

const initialValues = {
    email: "",
    firstname: "",
    birthdate: "",
    gender: 1,
    country: 105, //105: DE 106: AT 107: CH
    zipcode: "",
    username: "",
    password: "",
    cid: "",
    camp: ""
};


class MasterForm extends React.Component {
    static Page = ({ children }) => children;

    constructor(props) {
        super(props)


        this.state = {
            page: 0,
            values: initialValues,
            isValidating: true,
        }
    }


    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        if(values.cid) {
            var oldValues = this.state.values;
            oldValues.cid = values.cid;

            this.setState({
                values: oldValues
            });

        }

        if(values.camp) {
            var oldValues = this.state.values;
            oldValues.camp = values.camp;

            this.setState({
                values: oldValues
            });

        }

    }

    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values,
        }));

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0),
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
            ];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };


    handleSubmit = (values, bag) => {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values, bag);
        } else {
            bag.setTouched({});
            bag.setSubmitting(false);
            this.next(values);
        }
    };


    render() {
        const { children } = this.props;
        const { page, values } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;

        //console.log(activePage);

        const {  bindSubmitForm } = this.props;

        const props = this.pros;

        return (
                <Formik initialValues={initialValues}
                        enableReinitialize={false}
                        validate={this.validate}
                        onSubmit={this.handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        render={({ values, handleSubmit, isSubmitting, handleReset, setFieldValue, submitForm }) => {

                            return (<form id="registerform" onSubmit={handleSubmit}>
                                    {activePage}
                                    </form>)
                        } }>
              </Formik>
        );
    }
}

export default MasterForm;

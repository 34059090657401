var api_url = document.head.querySelector('meta[name="api-url"]').content;
const axios = require('axios');

export default class API  {

    checkZipCode(country, zip) {
        return this.checkWithServer("check/zipcode?zip=", zip+"&country="+country);
    }


    checkUsername(nickname) {
        return this.checkWithServer("check/nick?nick=", nickname);
    }



    checkEmail(email)
    {
       return this.checkWithServer("check/email?mail=", email);
    }


    checkWithServer(route, data) {
        return new Promise((resolve, reject) => {
            axios.get(this.apiUrl(route+data))
                .then(function (response) {
                    return response.data;
                })
                .then(function (response) {
                    if(response.status === 'success' || response.status === true)
                    {
                        resolve(response);
                    } else {
                        reject(response.response);
                    }
                })
                .catch(function (error) {
                    // handle error
                    //console.log("error", error);
                })
        });
    }


    apiUrl(endpoint) {
        return api_url+"/"+endpoint;
    }


    doRegister(data)
    {
        return new Promise((resolve, reject) => {
            axios.post(this.apiUrl('signup'), data)
                .then(function (response) {
                    return response.data;
                })
                .then(function (response) {
                    if(response.status === 'success' || response.status === true)
                    {
                        resolve(response);
                    } else {
                        reject(response.response);
                    }
                })
                .catch(function (error) {
                    // handle error
                    //console.log("error", error);
                })
        });
    }
}
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import App from "./App";


// Google Analytics
// https://github.com/react-ga/react-ga#api
ReactGA.initialize('UA-130649651-6', {
    debug: false,
})

// Sentry
// https://docs.sentry.io/clients/javascript/integrations/react/
//window.Raven.config(process.env.REACT_APP_SENTRY_RAVEN_TRACKING_URL).install()

// render the App component to our document root with React
ReactDOM.render(<App />, document.getElementById('root'))

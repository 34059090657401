import React from 'react';
import './style.scss';

import RegisterPage from "./screens/RegisterPage/RegisterPage";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class App extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
                <div>
                    <div className="container-fluid fullPageWrapper">
                        <div className="contentWrapper">
                            <div className="App">
                                <Router>
                                    <Route path="/" exact component={RegisterPage} />
                                </Router>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
